// card
.card {
  .card-header,
  .card-body,
  .card-footer {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

// accordion
.accordion-item.card {
  overflow: hidden;

  &:not(:first-of-type, :last-of-type) {
    border-radius: 0;
  }

  &:first-of-type:not(:only-of-type) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  &:last-of-type:not(:only-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .card-header {
    padding: 0;
    margin-bottom: calc(-1 * $border-width);
    border-color: $accordion-border-color;
    border-radius: 0;

    .card-header-tabs {
      flex-wrap: nowrap;
      margin-right: calc(0.5 * $card-cap-padding-x);
      margin-bottom: calc(-1 * $border-width);
      margin-left: calc(0.5 * $card-cap-padding-x);
      overflow-x: auto;
      white-space: nowrap;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .accordion-button {
    background-color: transparent;

    &:focus {
      box-shadow: 0 0 $input-btn-focus-blur $input-btn-focus-width
        $input-btn-focus-color inset;
    }

    &:not(.collapsed) {
      color: inherit;
      background-color: transparent;

      &:not(:focus) {
        box-shadow: none;
      }
    }

    &::after {
      content: none;
    }
  }
}

// dropdown
.dropdown[data-popper-reference-hidden] {
  pointer-events: none;
  visibility: hidden;
}

.dropdown-toggle:not(.dropdown-toggle-split)::after {
  display: none !important;
}

// form
.form-control[readonly] {
  color: $input-disabled-color;
  background-color: $input-disabled-bg;
  border-color: $input-disabled-border-color;
  opacity: 1;
}

// nav & tab
.tab-pane {
  overflow: hidden;

  &.active {
    display: flex;
    flex-direction: column;
  }
}

// table
.table-responsive {
  overflow-y: auto;
}

.table {
  > thead > tr > th {
    position: sticky;
    top: 0;
    z-index: 10;
    border: none;

    &::before {
      top: 0;
      left: 0;
    }

    &::after {
      bottom: 0;
      left: 0;
    }

    &::before,
    &::after {
      position: absolute;
      width: 100%;
      height: 1px;
      content: '';
      background: $table-border-color;
    }
  }

  > tbody > tr > td {
    background-color: $body-bg;
  }

  > tfoot > tr > th {
    position: sticky;
    bottom: 0;
    z-index: 10;
    background-color: $body-bg;
    border: none;

    &::before {
      position: absolute;
      top: -1px;
      left: 0;
      width: 100%;
      height: 1px;
      content: '';
      background: $table-border-color;
    }
  }

  > thead > tr > th,
  > tbody > tr > td,
  > tfoot > tr > th {
    padding: 0.75rem 0.25rem;
    white-space: nowrap;
    vertical-align: middle;

    &:first-child {
      padding-left: 0.75rem;
    }

    &:last-child {
      padding-right: 0.75rem;
    }
  }

  > :not(caption) > * > * {
    color: inherit;
  }
}

// custom file input
$custom-forms-transition: background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;
$custom-file-height: $input-height !default;
$custom-file-focus-border-color: $input-focus-border-color !default;
$custom-file-focus-box-shadow: $input-focus-box-shadow !default;
$custom-file-disabled-bg: $input-disabled-bg !default;
$custom-file-padding-y: $input-padding-y !default;
$custom-file-padding-x: $input-padding-x !default;
$custom-file-line-height: $input-line-height !default;
$custom-file-font-family: $input-font-family !default;
$custom-file-font-weight: $input-font-weight !default;
$custom-file-color: $input-color !default;
$custom-file-bg: $input-bg !default;
$custom-file-border-width: $input-border-width !default;
$custom-file-border-color: $input-border-color !default;
$custom-file-border-radius: $input-border-radius !default;
$custom-file-box-shadow: $input-box-shadow !default;

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: $custom-file-height;
  margin-bottom: 0;
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: $custom-file-height;
  padding: $custom-file-padding-y $custom-file-padding-x;
  overflow: hidden;
  font-family: $custom-file-font-family;
  font-weight: $custom-file-font-weight;
  line-height: $custom-file-line-height;
  color: $custom-file-color;
  background-color: $custom-file-bg;
  border: $custom-file-border-width solid $custom-file-border-color;

  @include border-radius($custom-file-border-radius);
  @include box-shadow($custom-file-box-shadow);
  @include transition($custom-forms-transition);
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: $custom-file-height;
  margin: 0;
  overflow: hidden;
  opacity: 0;

  &:focus ~ .custom-file-label {
    border-color: $custom-file-focus-border-color;
    box-shadow: $custom-file-focus-box-shadow;
  }

  &[disabled] ~ .custom-file-label,
  &:disabled ~ .custom-file-label {
    background-color: $custom-file-disabled-bg;
  }
}

.input-group {
  > .custom-file {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;

    &:not(:first-child) .custom-file-label {
      @include border-start-radius(0);
    }
  }

  &:not(.has-validation) {
    > :not(:last-child) .custom-file-label,
    > :not(:last-child) .custom-file-label::after {
      @include border-end-radius(0);
    }
  }

  &.has-validation {
    > :nth-last-child(n + 3) .custom-file-label,
    > :nth-last-child(n + 3) .custom-file-label::after {
      @include border-end-radius(0);
    }
  }

  > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3;
  }

  > .custom-file .custom-file-input:focus {
    z-index: 4;
  }
}

@mixin custom-file-validation-state(
  $state,
  $color,
  $icon,
  $tooltip-color,
  $tooltip-bg-color,
  $focus-box-shadow,
  $border-color
) {
  .custom-file {
    @include form-validation-state-selector($state) {
      > .custom-file-label {
        border-color: $color;

        @if $enable-validation-icons {
          padding-right: $input-height-inner;
          background-image: escape-svg($icon);
          background-repeat: no-repeat;
          background-position: right $input-height-inner-quarter center;
          background-size: $input-height-inner-half $input-height-inner-half;
        }
      }

      > .custom-file-input:focus ~ .custom-file-label {
        border-color: $color;
        box-shadow: 0 0 0 $input-focus-width rgba($color, 0.25);
      }
    }
  }
}

@each $state, $data in $form-validation-states {
  @include custom-file-validation-state($state, $data...);
}

// ng-select
.ng-select.custom {
  @include border-radius($form-select-border-radius, 0);

  > .ng-select-container {
    min-height: $input-height;
    color: $form-select-color;
    background-color: $form-select-bg;
    border: $form-select-border-width solid $form-select-border-color;
    border-radius: inherit;

    &:hover {
      box-shadow: none;
    }

    .ng-placeholder {
      color: $input-placeholder-color;
    }
  }

  &.ng-select-disabled > .ng-select-container {
    background-color: $form-select-disabled-bg;
  }

  &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    border-color: $form-select-focus-border-color;
    outline: 0;

    @if $enable-shadows {
      @include box-shadow(
        $form-select-box-shadow,
        $form-select-focus-box-shadow
      );
    } @else {
      box-shadow: $form-select-focus-box-shadow;
    }
  }
}

.input-group > .ng-select.custom {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;

  &.ng-select-focused:not(.ng-select-opened) {
    z-index: 5;
  }
}

.ng-dropdown-panel {
  z-index: 1055 !important;
}

@mixin custom-select-validation-state(
  $state,
  $color,
  $icon,
  $tooltip-color,
  $tooltip-bg-color,
  $focus-box-shadow,
  $border-color
) {
  .ng-select.custom {
    @include form-validation-state-selector($state) {
      > .ng-select-container {
        border-color: $color;
      }

      &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
        border-color: $color;
        box-shadow: 0 0 0 $input-focus-width rgba($color, 0.25);
      }
    }
  }
}

@each $state, $data in $form-validation-states {
  @include custom-select-validation-state($state, $data...);
}
